<template>
  <div class="overflow-y-scroll scrollbar-hide h-full">
    <div class="relative m-auto z-10 mb-48">

      <div class="top-profile">
        <div class="flex justify-between items-start">
          <router-link class="pr-8" :to="{name : 'HomeRealEstate'}"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
            <defs>
              <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
              </filter>
            </defs>
            <g id="Group_12961" data-name="Group 12961" transform="translate(-308 -28)">
              <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
                <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                  <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                    <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                  </g>
                </g>
              </g>
              <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
            </g>
          </svg>
          </router-link>
          <ShareNetwork
              network="whatsapp"
              :title="` ارسال صفحه بنگاه ${user.real_estate.name}   `"
              :url=" url + '/customer/page/' +user.id"
              class="share-btn ml-12">
            انتشار
            <svg xmlns="http://www.w3.org/2000/svg" width="45.531" height="47.75" viewBox="0 0 45.531 47.75">
              <path id="Icon_awesome-share-alt" data-name="Icon awesome-share-alt" d="M12.2,11.094a3.314,3.314,0,0,0-2.073.724L6.577,9.6a3.347,3.347,0,0,0,0-1.445L10.13,5.932A3.322,3.322,0,1,0,8.954,4.051L5.4,6.271a3.328,3.328,0,1,0,0,5.207L8.954,13.7A3.328,3.328,0,1,0,12.2,11.094Z" transform="translate(15 15)" fill="#fff"/>
            </svg>
          </ShareNetwork>
        </div>

        <div class="bg-profile"></div>
        <div class="relative">
          <img :src="baseURL + user.real_estate.logo" alt="" class="rounded-full w-24 mx-auto">
          <div class="expire" v-if="user.subscription_info">{{user.subscription_info.data.days_left}}<span>روز اعتبار</span></div>
          <div class="expire bg-red" v-else>پایان اعتبار شما</div>
        </div>
        <h5>{{user.real_estate.name}}</h5>
      </div>
      <div class="mx-4 mb-5">
        <div class="tab-profile-bongah">
          <ul>
            <li :class="activeTab === 1 ? 'active' : ''" @click="activeTab = 1"><button>مشخصات</button></li>
            <li :class="activeTab === 2 ? 'active' : ''" @click="activeTab = 2"><button>لیست املاک</button></li>
            <li :class="activeTab === 3 ? 'active' : ''" @click="activeTab = 3"><button>نظرات</button></li>
          </ul>
        </div>
        <Details @change-data="updateUser" v-if="activeTab === 1"/>
        <Estates v-if="activeTab === 2" />
        <Comments v-if="activeTab === 3" />

      </div>
      <RealEstateButton />
    </div>
  </div>
</template>

<script>
import Details from "./Details";
import Estates from "./Estates";
import Comments from "./Comments";
import RealEstateButton from '../navigationButton/RealEstateButton'
export default {
  name: "RealEstateProfile",
  components :{
    Estates,
    Details,
    Comments,
    RealEstateButton
  },
  data(){
    return{
      activeTab : 1,
      user : null,
      baseURL : this.$store.state.general.baseURL,
      url : this.$store.state.general.url
    }
  },
  methods:{
    updateUser(data){
      this.user = data
    }
  },
  created() {
    let user = JSON.parse(localStorage.getItem('user'))
    this.user = user
  }
}
</script>

<style>

</style>