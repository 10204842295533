<template>
  <div class="h-full">
   <RealEstateProfile />
  </div>
</template>

<script>
import RealEstateProfile from '../../components/realEstateProfile'

export default {
  name: "ProfileRealEstate",
  components: {
    RealEstateProfile,
  }

}
</script>

<style scoped>

</style>