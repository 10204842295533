<template>
  <div>
    <a class="btn-custom mt-4" @click="handleSupport">
      پشتیبانی بانک بنگاه
      <span class="w-4 h-4 transform rotate-0">
        <img class="left-0 top-0 absolute w-16" src="../../assets/images/logo-profile.png" alt="">
      </span>
    </a>
    <a class="btn-custom mt-4" @click="()=>this.$router.push({name : 'RealEstateSubscription'})">
      تمدید اشتراک شما
      <span class="svg-btn-icon bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px"
        d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z"
        transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
    </a>
    <div class="box-profile">
      <label class="block relative ">
        <span class="text-white opacity-50 text-sm">نام</span>
        <input
            type="text"
            v-model="first_name"
            class="mt-0 block  text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white   focus:ring-0 focus:border-white">
        <span class="absolute top-8">
         <svg xmlns="http://www.w3.org/2000/svg" width="12.767" height="12.767" viewBox="0 0 12.767 12.767">
            <path id="ic_person_24px"
                  d="M10.384,10.384A3.192,3.192,0,1,0,7.192,7.192,3.191,3.191,0,0,0,10.384,10.384Zm0,1.6C8.253,11.98,4,13.049,4,15.171v1.6H16.767v-1.6C16.767,13.049,12.514,11.98,10.384,11.98Z"
                  transform="translate(-4 -4)" fill="#ff7400"/>
         </svg>
        </span>
      </label>
      <label class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">نام خانوادگی</span>
        <input
            type="text"
            v-model="last_name"
            class="mt-0 block  text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white">
        <span class="absolute top-8">
         <svg xmlns="http://www.w3.org/2000/svg" width="12.767" height="12.767" viewBox="0 0 12.767 12.767">
            <path id="ic_person_24px"
                  d="M10.384,10.384A3.192,3.192,0,1,0,7.192,7.192,3.191,3.191,0,0,0,10.384,10.384Zm0,1.6C8.253,11.98,4,13.049,4,15.171v1.6H16.767v-1.6C16.767,13.049,12.514,11.98,10.384,11.98Z"
                  transform="translate(-4 -4)" fill="#ff7400"/>
         </svg>
        </span>
      </label>
      <label class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">شماره ملی</span>
        <input
            type="text"
            disabled
            :value="user.national_code"
            class="mt-0 white-disabled block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white opacity-10 focus:opacity-100  focus:ring-0 focus:border-white">
        <span class="absolute top-8">
         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="ic_portrait_24px"
                  d="M12,12.25A2.25,2.25,0,1,0,9.75,10,2.253,2.253,0,0,0,12,12.25Zm4.5,4c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5,2.25V17h9ZM19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V5H19Z"
                  transform="translate(-3 -3)" fill="#ff7400"/>
         </svg>
        </span>
      </label>
      <label class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">شماره همراه</span>
        <input
            type="text"
            v-model="user.phone_number"
            disabled
            class="mt-0 white-disabled block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white opacity-10 focus:opacity-100  focus:ring-0 focus:border-white">
        <span class="absolute top-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.675" height="12.675" viewBox="0 0 12.675 12.675">
              <path id="ic_phone_in_talk_24px"
                    d="M14.971,11.8a8,8,0,0,1-2.514-.4.719.719,0,0,0-.718.169L10.19,13.119A10.6,10.6,0,0,1,5.549,8.479L7.1,6.922a.676.676,0,0,0,.176-.7,8,8,0,0,1-.4-2.514.706.706,0,0,0-.7-.7H3.7a.706.706,0,0,0-.7.7A11.97,11.97,0,0,0,14.971,15.675a.706.706,0,0,0,.7-.7V12.506A.706.706,0,0,0,14.971,11.8Zm-.7-2.465h1.408A6.338,6.338,0,0,0,9.338,3V4.408A4.926,4.926,0,0,1,14.267,9.338Zm-2.817,0h1.408A3.522,3.522,0,0,0,9.338,5.817V7.225A2.11,2.11,0,0,1,11.45,9.338Z"
                    transform="translate(-3 -3)" fill="#ff7400"/>
            </svg>
        </span>
      </label>
    </div>
    <div class="box-profile">
      <label class="block relative ">
        <span class="text-white opacity-50 text-sm">تلفن بنگاه</span>
        <input
            type="text"
            disabled
            v-model="user.real_estate.telephone"
            class="mt-0 block white-disabled text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white opacity-10 focus:opacity-100  focus:ring-0 focus:border-white">
        <span class="absolute top-8">
         <svg xmlns="http://www.w3.org/2000/svg" width="12.675" height="12.675" viewBox="0 0 12.675 12.675">
              <path id="ic_phone_in_talk_24px"
                    d="M14.971,11.8a8,8,0,0,1-2.514-.4.719.719,0,0,0-.718.169L10.19,13.119A10.6,10.6,0,0,1,5.549,8.479L7.1,6.922a.676.676,0,0,0,.176-.7,8,8,0,0,1-.4-2.514.706.706,0,0,0-.7-.7H3.7a.706.706,0,0,0-.7.7A11.97,11.97,0,0,0,14.971,15.675a.706.706,0,0,0,.7-.7V12.506A.706.706,0,0,0,14.971,11.8Zm-.7-2.465h1.408A6.338,6.338,0,0,0,9.338,3V4.408A4.926,4.926,0,0,1,14.267,9.338Zm-2.817,0h1.408A3.522,3.522,0,0,0,9.338,5.817V7.225A2.11,2.11,0,0,1,11.45,9.338Z"
                    transform="translate(-3 -3)" fill="#ff7400"/>
            </svg>
        </span>
      </label>
      <label class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">نام بنگاه</span>
        <input
            type="text"
            v-model="name"
            class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white ">
        <span class="absolute top-8">
         <svg xmlns="http://www.w3.org/2000/svg" width="11.586" height="11.73" viewBox="0 0 11.586 11.73">
           <path id="ic_blur_linear_24px"
                 d="M4.287,12.449a.978.978,0,1,0-.966-.977A.97.97,0,0,0,4.287,12.449ZM6.862,9.516a.652.652,0,1,0-.644-.652A.65.65,0,0,0,6.862,9.516Zm0-2.607a.652.652,0,1,0-.644-.652A.65.65,0,0,0,6.862,6.91ZM3,14.73H14.586v-1.3H3ZM4.287,7.236a.978.978,0,1,0-.966-.977A.97.97,0,0,0,4.287,7.236Zm0,2.607a.978.978,0,1,0-.966-.977A.97.97,0,0,0,4.287,9.842Zm2.575,2.281a.652.652,0,1,0-.644-.652A.65.65,0,0,0,6.862,12.123Zm5.15-.326a.326.326,0,1,0-.322-.326A.321.321,0,0,0,12.012,11.8ZM3,3V4.3H14.586V3Zm9.012,3.584a.326.326,0,1,0-.322-.326A.321.321,0,0,0,12.012,6.584Zm0,2.607a.326.326,0,1,0-.322-.326A.321.321,0,0,0,12.012,9.191ZM9.437,6.91a.652.652,0,1,0-.644-.652A.65.65,0,0,0,9.437,6.91Zm0,2.607a.652.652,0,1,0-.644-.652A.65.65,0,0,0,9.437,9.516Zm0,2.607a.652.652,0,1,0-.644-.652A.65.65,0,0,0,9.437,12.123Z"
                 transform="translate(-3 -3)" fill="#ff7400"/>
         </svg>
        </span>
      </label>
      <label class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">کد جواز</span>
        <input
            type="text"
            v-model="user.real_estate.license_id"
            disabled
            class="mt-0 block white-disabled text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white ">
        <span class="absolute top-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.119" height="13.836" viewBox="0 0 12.119 13.836">
              <path id="ic_local_library_24px"
                    d="M9.06,8.43A8.856,8.856,0,0,0,3,6.04v7.406a8.856,8.856,0,0,1,6.06,2.39,8.887,8.887,0,0,1,6.06-2.39V6.04A8.856,8.856,0,0,0,9.06,8.43Zm0-2.39A2.02,2.02,0,1,0,7.04,4.02,2.017,2.017,0,0,0,9.06,6.04Z"
                    transform="translate(-3 -2)" fill="#ff7400"/>
            </svg>
        </span>
      </label>
      <div class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">عکس جواز</span>
        <span class="absolute top-8 right-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.13" height="11.988" viewBox="0 0 13.13 11.988">
              <path id="ic_add_a_photo_24px"
                    d="M1.713,2.713V1H2.854V2.713H4.567V3.854H2.854V5.567H1.713V3.854H0V2.713ZM3.425,6.138V4.425H5.138V2.713h4l1.045,1.142h1.81A1.145,1.145,0,0,1,13.13,5v6.85a1.145,1.145,0,0,1-1.142,1.142H2.854a1.145,1.145,0,0,1-1.142-1.142V6.138Zm4,5.138A2.854,2.854,0,1,0,4.567,8.421,2.855,2.855,0,0,0,7.421,11.275ZM5.594,8.421A1.827,1.827,0,1,0,7.421,6.594,1.825,1.825,0,0,0,5.594,8.421Z"
                    transform="translate(0 -1)" fill="#ff7400"/>
            </svg>
        </span>
        <div class="images-profile">
          <img class="w-24" :src="baseURL+ user.real_estate.license_picture" alt="">
        </div>
      </div>
      <div class="block relative mt-7">
        <p class="text-sm text-white mt-8">عکس های بنگاه</p>
        <div class="images-profile">
          <Uploader
              v-model="ShowImages"
              :autoUpload="false"
              title=""
              :limit="3"
              @on-delete="onDelete"
              @on-change="handleImages"
              class="upload-component"
              :limitPrompt="l => `تعداد آپلود مجاز ${l} می باشد`"
          />
        </div>
      </div>

    </div>
    <div class="box-profile">
      <label class="block relative ">
        <span class="text-white opacity-50 text-sm">استان</span>
        <input
            type="text"
            v-model="user.real_estate.province"
            disabled
            class="mt-0 block white-disabled text-white w-full pl-0.5 py-2.5 pr-1 border-0 border-b border-white opacity-10 focus:opacity-100  focus:ring-0 focus:border-white">
      </label>
      <label class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">شهر</span>
        <input
            type="text"
            v-model="user.real_estate.city"
            disabled
            class="mt-0 block white-disabled text-white w-full pl-0.5 py-2.5 pr-1 border-0 border-b border-white opacity-10 focus:opacity-100  focus:ring-0 focus:border-white">
      </label>
      <label class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">آدرس</span>
        <input
            type="text"
            v-model="user.real_estate.address"
            disabled
            class="mt-0 block white-disabled text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white opacity-10 focus:opacity-100  focus:ring-0 focus:border-white">
        <span class="absolute top-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="10.012" height="14.303" viewBox="0 0 10.012 14.303">
              <path id="ic_place_24px"
                    d="M10.006,2A5,5,0,0,0,5,7.006c0,3.755,5.006,9.3,5.006,9.3s5.006-5.542,5.006-9.3A5,5,0,0,0,10.006,2Zm0,6.794a1.788,1.788,0,1,1,1.788-1.788A1.789,1.789,0,0,1,10.006,8.794Z"
                    transform="translate(-5 -2)" fill="#ff7400"/>
            </svg>
        </span>
      </label>
      <label class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">کد معرف</span>
        <input
            type="text"
            v-model="user.real_estate.economic_code"
            disabled
            class="mt-0 block white-disabled text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white opacity-10 focus:opacity-100  focus:ring-0 focus:border-white">
        <span class="absolute top-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="9.864" viewBox="0 0 15.5 9.864">
              <path id="ic_group_24px"
                    d="M11.568,9.227A2.114,2.114,0,1,0,9.455,7.114,2.1,2.1,0,0,0,11.568,9.227Zm-5.636,0A2.114,2.114,0,1,0,3.818,7.114,2.1,2.1,0,0,0,5.932,9.227Zm0,1.409C4.29,10.636,1,11.461,1,13.1v1.761h9.864V13.1C10.864,11.461,7.573,10.636,5.932,10.636Zm5.636,0c-.2,0-.437.014-.683.035A2.973,2.973,0,0,1,12.273,13.1v1.761H16.5V13.1C16.5,11.461,13.21,10.636,11.568,10.636Z"
                    transform="translate(-1 -5)" fill="#ff7400"/>
            </svg>
        </span>
      </label>
    </div>
    <div class="box-profile">
      <label class="block relative ">
        <span class="text-white opacity-50 text-sm">نام کاربری</span>
        <input
            type="text"
            v-model="username"
            class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white ">
        <span class="absolute top-8">
              <svg xmlns="http://www.w3.org/2000/svg" width="12.767" height="12.767" viewBox="0 0 12.767 12.767">
                <path id="ic_person_24px"
                      d="M10.384,10.384A3.192,3.192,0,1,0,7.192,7.192,3.191,3.191,0,0,0,10.384,10.384Zm0,1.6C8.253,11.98,4,13.049,4,15.171v1.6H16.767v-1.6C16.767,13.049,12.514,11.98,10.384,11.98Z"
                      transform="translate(-4 -4)" fill="#ff7400"/>
              </svg>
        </span>
      </label>
      <!--      <label class="block relative mt-7">
              <span class="text-white opacity-50 text-sm"> رمز عبور</span>
              <input
                  :type="PasswordVisible ? 'text' : 'password'"
                  class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white">
              <span class="absolute top-8" @click="PasswordVisible = !PasswordVisible">
                <svg v-if="!PasswordVisible" id="eye-view" xmlns="http://www.w3.org/2000/svg" width="16.404"
                     height="14.248" viewBox="0 0 16.404 14.248">
                  <g id="Group_86" data-name="Group 86" transform="translate(0 1.749)">
                    <path id="Path_160" data-name="Path 160"
                          d="M14.136,97.074A8.568,8.568,0,0,1,8,99.552a8.607,8.607,0,0,1-6.139-2.478,8.956,8.956,0,0,1-1.812-2.5.54.54,0,0,1,0-.451,7.655,7.655,0,0,1,1.765-2.347,8.944,8.944,0,0,1,6.242-2.3,8.747,8.747,0,0,1,6.186,2.309,7.255,7.255,0,0,1,1.718,2.356.532.532,0,0,1-.009.432A8.73,8.73,0,0,1,14.136,97.074Zm-6.054-6.43a2.741,2.741,0,1,0,2.741,2.741A2.744,2.744,0,0,0,8.082,90.645Z"
                          transform="translate(0.002 -89.481)" fill="#ff7500"/>
                  </g>
                  <path id="Path_2136" data-name="Path 2136" d="M0,16.615H1.352L2.059,0H.707Z"
                        transform="translate(4.656 13.204) rotate(-135)" fill="#fff"/>
                  <path id="Path_2137" data-name="Path 2137" d="M0,18.091H1.352L2.059,0H.707Z"
                        transform="translate(2.007 14.248) rotate(-135)" fill="#ff7500"/>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="10.071" viewBox="0 0 16 10.071">
        <path id="Path_160" data-name="Path 160"
              d="M14.136,97.074A8.568,8.568,0,0,1,8,99.552a8.607,8.607,0,0,1-6.139-2.478,8.956,8.956,0,0,1-1.812-2.5.54.54,0,0,1,0-.451,7.655,7.655,0,0,1,1.765-2.347,8.944,8.944,0,0,1,6.242-2.3,8.747,8.747,0,0,1,6.186,2.309,7.255,7.255,0,0,1,1.718,2.356.532.532,0,0,1-.009.432A8.73,8.73,0,0,1,14.136,97.074Zm-6.054-6.43a2.741,2.741,0,1,0,2.741,2.741A2.744,2.744,0,0,0,8.082,90.645Z"
              transform="translate(0.002 -89.481)" fill="#ff7500"/>
      </svg>
              </span>
            </label>-->
      <div class="block relative mt-7">
        <span class="text-white opacity-50 text-sm">لوگو بنگاه</span>
        <span class="absolute top-8 right-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.13" height="11.988" viewBox="0 0 13.13 11.988">
              <path id="ic_add_a_photo_24px"
                    d="M1.713,2.713V1H2.854V2.713H4.567V3.854H2.854V5.567H1.713V3.854H0V2.713ZM3.425,6.138V4.425H5.138V2.713h4l1.045,1.142h1.81A1.145,1.145,0,0,1,13.13,5v6.85a1.145,1.145,0,0,1-1.142,1.142H2.854a1.145,1.145,0,0,1-1.142-1.142V6.138Zm4,5.138A2.854,2.854,0,1,0,4.567,8.421,2.855,2.855,0,0,0,7.421,11.275ZM5.594,8.421A1.827,1.827,0,1,0,7.421,6.594,1.825,1.825,0,0,0,5.594,8.421Z"
                    transform="translate(0 -1)" fill="#ff7400"/>
            </svg>
        </span>
        <div class="images-profile">
          <img class="w-24 h-24 inline-block"
               :src="[logoPreview !==null ?logoPreview : baseURL+  user.real_estate.logo]" alt="">
          <validation-provider name="عکس جواز" rules="ext:jpg,png,jpeg,gif" ref="imageInput"
                               v-slot="{ errors, validate }">
            <div class="logo-upload">
              <input
                  type="file"
                  @change="onFileSelected"
                  class="file-upload-logo mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white">
              <svg xmlns="http://www.w3.org/2000/svg" width="30.884" height="28.894" viewBox="0 0 22.884 20.894">
                <path id="ic_add_a_photo_24px"
                      d="M2.985,3.985V1h1.99V3.985H7.959v1.99H4.975V8.959H2.985V5.975H0V3.985ZM5.97,9.954V6.97H8.954V3.985h6.965l1.821,1.99h3.154a2,2,0,0,1,1.99,1.99V19.9a2,2,0,0,1-1.99,1.99H4.975a2,2,0,0,1-1.99-1.99V9.954Zm6.965,8.954a4.975,4.975,0,1,0-4.975-4.975A4.976,4.976,0,0,0,12.934,18.909ZM9.75,13.934a3.184,3.184,0,1,0,3.184-3.184A3.18,3.18,0,0,0,9.75,13.934Z"
                      transform="translate(0 -1)" fill="#ff7400"/>
              </svg>
            </div>
          </validation-provider>
        </div>
        <div class="border-b border-gray-500 opacity-20  w-full mt-10 mb-6 mx-auto"></div>

        <div>
          <div class="block relative ">
            <input
                type="number"
                v-model="phones[0].telephone"
                placeholder="شماره مشاور 1"
                class="mt-0 block px-2 text-white text-left w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white ">
            <span class="absolute top-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="12.675" height="12.675" viewBox="0 0 12.675 12.675">
  <path id="ic_phone_in_talk_24px"
        d="M14.971,11.8a8,8,0,0,1-2.514-.4.719.719,0,0,0-.718.169L10.19,13.119A10.6,10.6,0,0,1,5.549,8.479L7.1,6.922a.676.676,0,0,0,.176-.7,8,8,0,0,1-.4-2.514.706.706,0,0,0-.7-.7H3.7a.706.706,0,0,0-.7.7A11.97,11.97,0,0,0,14.971,15.675a.706.706,0,0,0,.7-.7V12.506A.706.706,0,0,0,14.971,11.8Zm-.7-2.465h1.408A6.338,6.338,0,0,0,9.338,3V4.408A4.926,4.926,0,0,1,14.267,9.338Zm-2.817,0h1.408A3.522,3.522,0,0,0,9.338,5.817V7.225A2.11,2.11,0,0,1,11.45,9.338Z"
        transform="translate(-3 -3)" fill="#ff7400"/>
</svg>
        </span>
          </div>
          <div class="block relative my-6">
            <input
                type="number"
                v-model="phones[1].telephone"
                placeholder="شماره مشاور 2"
                class="mt-0 block px-2 text-white text-left w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white ">
            <span class="absolute top-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="12.675" height="12.675" viewBox="0 0 12.675 12.675">
  <path id="ic_phone_in_talk_24px"
        d="M14.971,11.8a8,8,0,0,1-2.514-.4.719.719,0,0,0-.718.169L10.19,13.119A10.6,10.6,0,0,1,5.549,8.479L7.1,6.922a.676.676,0,0,0,.176-.7,8,8,0,0,1-.4-2.514.706.706,0,0,0-.7-.7H3.7a.706.706,0,0,0-.7.7A11.97,11.97,0,0,0,14.971,15.675a.706.706,0,0,0,.7-.7V12.506A.706.706,0,0,0,14.971,11.8Zm-.7-2.465h1.408A6.338,6.338,0,0,0,9.338,3V4.408A4.926,4.926,0,0,1,14.267,9.338Zm-2.817,0h1.408A3.522,3.522,0,0,0,9.338,5.817V7.225A2.11,2.11,0,0,1,11.45,9.338Z"
        transform="translate(-3 -3)" fill="#ff7400"/>
</svg>
        </span>
          </div>
          <div class="block relative ">
            <input
                type="number"
                v-model="phones[2].telephone"
                placeholder="شماره مشاور 3"
                class="mt-0 block px-2 text-white text-left w-full pl-0.5 py-2.5 pr-6 border-0 border-b border-white ">
            <span class="absolute top-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="12.675" height="12.675" viewBox="0 0 12.675 12.675">
  <path id="ic_phone_in_talk_24px"
        d="M14.971,11.8a8,8,0,0,1-2.514-.4.719.719,0,0,0-.718.169L10.19,13.119A10.6,10.6,0,0,1,5.549,8.479L7.1,6.922a.676.676,0,0,0,.176-.7,8,8,0,0,1-.4-2.514.706.706,0,0,0-.7-.7H3.7a.706.706,0,0,0-.7.7A11.97,11.97,0,0,0,14.971,15.675a.706.706,0,0,0,.7-.7V12.506A.706.706,0,0,0,14.971,11.8Zm-.7-2.465h1.408A6.338,6.338,0,0,0,9.338,3V4.408A4.926,4.926,0,0,1,14.267,9.338Zm-2.817,0h1.408A3.522,3.522,0,0,0,9.338,5.817V7.225A2.11,2.11,0,0,1,11.45,9.338Z"
        transform="translate(-3 -3)" fill="#ff7400"/>
</svg>
        </span>
          </div>
        </div>
        <div class="border-b border-gray-500 opacity-20  w-full mt-10 mb-6 mx-auto"></div>
        <label class="block relative mt-7">
          <span class="text-white opacity-50 text-sm">آدرس اینستاگرام</span>
          <input
              type="text"
              v-model="instagramLink"
              placeholder="https://instagram.com/pouyagaranco  بطور مثال"
              class="mt-0 block text-white text-left w-full pl-0.5 mt-8 py-2.5 px-2 border-0 border-b border-white  focus:opacity-100  focus:ring-0 focus:border-white">
          <span class="absolute top-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.333" height="14.333" viewBox="0 0 14.333 14.333">
  <path id="Icon_simple-instagram" data-name="Icon simple-instagram"
        d="M7.167,0C5.22,0,4.977.009,4.212.043a5.284,5.284,0,0,0-1.74.333A3.51,3.51,0,0,0,1.2,1.2a3.5,3.5,0,0,0-.827,1.27,5.269,5.269,0,0,0-.333,1.74C.007,4.977,0,5.22,0,7.167s.009,2.19.043,2.954a5.288,5.288,0,0,0,.333,1.74A3.515,3.515,0,0,0,1.2,13.13a3.5,3.5,0,0,0,1.27.827,5.291,5.291,0,0,0,1.74.333c.764.036,1.008.043,2.954.043s2.19-.009,2.954-.043a5.3,5.3,0,0,0,1.74-.333,3.663,3.663,0,0,0,2.1-2.1,5.288,5.288,0,0,0,.333-1.74c.036-.764.043-1.008.043-2.954s-.009-2.19-.043-2.954a5.3,5.3,0,0,0-.333-1.74A3.517,3.517,0,0,0,13.13,1.2a3.492,3.492,0,0,0-1.27-.827,5.272,5.272,0,0,0-1.74-.333C9.357.007,9.114,0,7.167,0Zm0,1.29c1.913,0,2.141.01,2.9.042a3.949,3.949,0,0,1,1.33.248,2.358,2.358,0,0,1,1.36,1.36A3.957,3.957,0,0,1,13,4.27c.034.756.042.983.042,2.9s-.009,2.141-.044,2.9a4.033,4.033,0,0,1-.251,1.33,2.276,2.276,0,0,1-.537.825,2.236,2.236,0,0,1-.824.535A3.986,3.986,0,0,1,10.051,13c-.761.034-.985.042-2.9.042s-2.142-.009-2.9-.044a4.064,4.064,0,0,1-1.335-.251,2.219,2.219,0,0,1-.824-.537,2.176,2.176,0,0,1-.537-.824A4.067,4.067,0,0,1,1.3,10.051c-.027-.752-.036-.985-.036-2.893s.01-2.142.036-2.9A4.063,4.063,0,0,1,1.55,2.92,2.124,2.124,0,0,1,2.088,2.1a2.12,2.12,0,0,1,.824-.536,3.967,3.967,0,0,1,1.326-.251c.761-.027.985-.036,2.9-.036l.027.018Zm0,2.2a3.68,3.68,0,1,0,3.68,3.68A3.68,3.68,0,0,0,7.167,3.487Zm0,6.069A2.389,2.389,0,1,1,9.556,7.167,2.388,2.388,0,0,1,7.167,9.556Zm4.686-6.214a.86.86,0,1,1-.86-.859A.861.861,0,0,1,11.852,3.341Z"
        fill="#ff7400"/>
</svg>
        </span>
        </label>
        <div class="border-b border-gray-500 opacity-20  w-full my-10 mx-auto"></div>
        <label class="block relative mt-7">
          <span class="text-white opacity-50 text-sm">شماره واتساپ پشتیبانی بنگاه</span>
          <input
              type="text"
              v-model="whatsappLink"
              placeholder="شماره واتساپ پشتیبان را وارد کنید"
              class="mt-0 block text-white text-left w-full pl-0.5 mt-8 py-2.5 px-2 border-0 border-b border-white  focus:opacity-100  focus:ring-0 focus:border-white">
          <span class="absolute top-8">
              <svg xmlns="http://www.w3.org/2000/svg" width="12.675" height="12.675" viewBox="0 0 12.675 12.675">
  <path id="ic_phone_in_talk_24px"
        d="M14.971,11.8a8,8,0,0,1-2.514-.4.719.719,0,0,0-.718.169L10.19,13.119A10.6,10.6,0,0,1,5.549,8.479L7.1,6.922a.676.676,0,0,0,.176-.7,8,8,0,0,1-.4-2.514.706.706,0,0,0-.7-.7H3.7a.706.706,0,0,0-.7.7A11.97,11.97,0,0,0,14.971,15.675a.706.706,0,0,0,.7-.7V12.506A.706.706,0,0,0,14.971,11.8Zm-.7-2.465h1.408A6.338,6.338,0,0,0,9.338,3V4.408A4.926,4.926,0,0,1,14.267,9.338Zm-2.817,0h1.408A3.522,3.522,0,0,0,9.338,5.817V7.225A2.11,2.11,0,0,1,11.45,9.338Z"
        transform="translate(-3 -3)" fill="#ff7400"/>
</svg>
        </span>
        </label>
      </div>
    </div>
    <a class="btn-custom mt-4" @click="finish">
      ثبت تغییرات
      <span class="svg-btn-icon bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px"
        d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z"
        transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
    </a>
    <a class="btn-custom mt-4" @click="logout">
      خروج
      <span class="svg-btn-icon bg-red transform ">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px"
        d="M20.826,9.111H4.985l7.276-7.276L10.413,0,0,10.413,10.413,20.826l1.835-1.835L4.985,11.715H20.826Z"
        transform="translate(0 20.826) rotate(-90)" fill="#fafafa"/>
</svg>

        </span>
    </a>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import {getBase64Strings} from 'exif-rotate-js/lib';
import Uploader from "vux-uploader-component";


export default {
  name: "Details",
  components: {
    Uploader
  },
  data() {
    return {
      logoSelected: null,
      imageSrc: [],
      images: [],
      newDataImagesBase64: [],
      newDataImages: [],
      logoPreview: null,
      PasswordVisible: false,
      user: null,
      first_name: '',
      last_name: '',
      name: '',
      username: '',
      phones: [
        {
          telephone: "",
          title: "شماره مشاور 1"
        },
        {
          telephone: "",
          title: "شماره مشاور 2"
        },
        {
          telephone: "",
          title: "شماره مشاور 3"
        }
      ],
      socials: [
        {
          link: "",
          string: 'instagram'
        },
        {
          link: "",
          string: 'whatsapp'
        }
      ],
      instagramLink: null,
      whatsappLink: null,
      baseURL: this.$store.state.general.baseURL,
      ShowImages: [],
    }
  },
  methods: {
    async onFileSelected(e) {
      const {valid} = await this.$refs.imageInput.validate(e);
      if (valid) {
        this.logoSelected = e.target.files[0];
        this.logoPreview = URL.createObjectURL(this.logoSelected)
        this.imageSrcimageSrc = await getBase64Strings(e.target.files)
      } else {
        this.$toast.error('فرمت انتخاب شده معتبر  نمیباشد')
      }
    },
    handleSupport() {
      window.open('https://wa.me/message/ZWVICBC6K5QCA1')
    },
    updateUser() {
      axios.get('/users').then(({data}) => {
        localStorage.setItem('user', JSON.stringify(data.data))
        this.$emit('change-data', data.data)
      })
    },
    logout() {
      axios.post('/logout').then(() => {
        localStorage.removeItem('user')
        localStorage.removeItem('user-token')
        this.$router.push('/')
      })
    },
    handleImages(fileItem) {
      let img = fileItem.blob
      let reader = new FileReader();
      reader.onloadend = (file) => {
        this.newDataImagesBase64.push(reader.result)
      };
      reader.readAsDataURL(img);
    },
    async onDelete(deleteItem, cb) {
      let index = await this.ShowImages.indexOf(deleteItem)
      this.ShowImages.splice(index, 1);
    },
    finish() {
      if (this.first_name !== '' && this.last_name !== '' && this.name !== '') {
        let phones = this.phones.filter(item => item.telephone !== '' && item.telephone !== undefined)
        let social = [];
        if (this.instagramLink && this.instagramLink !== ''){
          social.push({
            link : this.instagramLink,
            string : 'instagram'
          })
        }
        if (this.whatsappLink && this.whatsappLink !== ''){
          social.push({
            link : this.whatsappLink,
            string : 'whatsapp'
          })
        }
        axios.put('users', {
          first_name: this.first_name,
          last_name: this.last_name,
          realEstate: {
            name: this.name,
            phones: phones,
            socials: social
          }
        }).then(() => {
          this.$toast.success('اطلاعات شما با موفقیت بروز شد')
          this.updateUser()
        })

        if (this.username !== this.user.username) {
          axios.put('users', {
            username: this.username
          }).then(({data}) => {
            if (data.status) {
              this.$toast.success('اطلاعات شما با موفقیت بروز شد')
              this.updateUser()
            } else {
              this.$toast.error(data.message.username[0])
            }
          })
        }
      }
      if (this.logoSelected !== null) {
        axios.put('users', {
          realEstate: {
            logo: this.imageSrc[0]
          }
        }).then(() => {
          this.$toast.success('لوگو شما با موفقیت بروز شد')
          this.updateUser()
        })
      }
      this.ShowImages.forEach((item, i) => {
        if (!item.type) {
          let link = this.ShowImages[i].url.replace('https://app.bankbongah.com', '')
          this.newDataImages[i] = link
        }
      })
      axios.post('/real-estates/' + this.user.real_estate.id + '/add-media', {
        media: [...this.newDataImages, ...this.newDataImagesBase64]
      }).then(() => {
        this.$toast.success('تصاویر بنگاه با موفقیت به روز رسانی شد')
      })

    },
    getInstaAndWhatsApp() {
      this.socials.forEach(item => {
        if (item.string === 'instagram') {
          this.instagramLink = item.link
        }
        if (item.string === "whatsapp") {
          this.whatsappLink = item.link
        }
      })
    }
  },
  computed: {},
  created() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.user = user;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.name = user.real_estate.name;
    this.username = user.username;
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.phones[0].telephone = user.real_estate.phones?.[0]?.telephone
    this.phones[1].telephone = user.real_estate.phones?.[1]?.telephone
    this.phones[2].telephone = user.real_estate.phones?.[2]?.telephone
    if (user.real_estate.socials !== undefined){
      this.socials = user.real_estate.socials
    }
    this.getInstaAndWhatsApp();
    this.user.real_estate.media.forEach((item) => {
      this.ShowImages.push({
        url: this.$store.state.general.baseURL + item.file
      })
    })
  }
}
</script>

<style scoped>
.upload-component {
  padding: unset;
}

.upload-component >>> .vux-uploader_hd {
  display: none !important;
}

.upload-component >>> .vux-uploader_input-box {
  float: right !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1.5rem;
  border: none;
}

.upload-component >>> .vux-uploader_input-box::after {
  content: '';
  background: url("../../assets/images/icons/shot_image.svg") center center no-repeat;
  width: 100%;
  height: 100%;
}

.upload-component >>> .vux-uploader_input-box::before {
  all: revert;
}

.upload-component >>> .vux-uploader_file {
  float: right !important;
  border-radius: 1.5rem;
}
</style>